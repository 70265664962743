<template>
  <div class="session session-content">
    <div class="content-inner">

      <div class="content-body car-show-body" v-if="car">

        <div class="car-show-t">
          <div class="box-1">
            <carrousel :photos="car.photolist"></carrousel>

          </div>
          <div class="box-2">
            <h1>{{ car.topmap.auTitle }}</h1>
            <div class="box-2-1">
              当前出价  <strong>10.89万</strong><span>(5位报价者)</span>
            </div>
            <ul>
              <li>
                <span>车况等级</span>
                <strong style="color: #f50014;">{{ car.topmap.adAppraiseGrade }}</strong>
              </li>
              <li>
                <span>缺陷数</span>
                <strong>{{ car.topmap.countDefect }}</strong>
              </li>
              <li>
                <span>车辆年份</span>
                <strong>{{ car.topmap.aciYearStyle }}</strong>
              </li>
              <li>
                <span>里程</span>
                <strong>{{ car.topmap.aciShowMileage }}万公里</strong>
              </li>
              <li>
                <span>上牌地</span>
                <strong>{{ car.topmap.pdCityName }}</strong>
              </li>
              <li>
                <span>上牌时间</span>
                <strong>{{ car.topmap.aciFirstPlateRegDate }}</strong>
              </li>
              <li>
                <span>变速箱</span>
                <strong>{{ car.topmap.aciDerailleurType }}</strong>
              </li>
              <li>
                <span>排放标准</span>
                <strong>{{ car.topmap.aciOutputStandard }}</strong>
              </li>
              <li>
                <span>状态</span>
                <strong style="color: #1c4e9e;">拍卖中</strong>
              </li>
              <!--<li>
                <span>剩余时间</span>
                <strong style="color: #1c4e9e;">15:50</strong>
              </li>-->
            </ul>
            <div class="box-2-2">
              <el-input placeholder="请输入您的报价金额"></el-input> <span>万</span>
            </div>
            <div class="box-2-3">
              <el-button type="primary" @click="onClickQrcode">确定</el-button>
            </div>

          </div>
          <div class="clear"></div>
        </div>

        <div class="car-show-b">
          <div class="box-1">
            <h3>查勘描述</h3>
            <div v-html="car.AppraiseDescription"></div>
          </div>

          <div class="box-2">
            <h3>车辆外观</h3>
            <ul class="box-2-1">
              <li v-if="fileUrl+car.photolist.acpBack">
                <img :src="fileUrl+car.photolist.acpBack">
                <!--<div>外观-右前：优秀  车况：无重大事故，无水泡，无火烧 ，性能部件正常使用</div>-->
              </li>
              <li v-if="fileUrl+car.photolist.acpBackChair">
                <img :src="fileUrl+car.photolist.acpBackChair">
              </li>
              <li v-if="fileUrl+car.photolist.acpFrontChair">
                <img :src="fileUrl+car.photolist.acpFrontChair">
              </li>
              <li v-if="fileUrl+car.photolist.acpHead">
                <img :src="fileUrl+car.photolist.acpHead">
              </li>
              <li class="clear"></li>
            </ul>

            <ul class="box-3-1">
              <li v-if="fileUrl+car.photolist.acpLeftHead">
                <img :src="fileUrl+car.photolist.acpLeftHead">
                <span @click="isShowMore=true"  v-if="!isShowMore">查看更多</span>
              </li>
              <li v-if="fileUrl+car.photolist.acpMiddleConsole">
                <img :src="fileUrl+car.photolist.acpMiddleConsole">
                <span @click="isShowMore=true"  v-if="!isShowMore">查看更多</span>
              </li>
              <li v-if="fileUrl+car.photolist.acpRightBack">
                <img :src="fileUrl+car.photolist.acpRightBack">
                <span @click="isShowMore=true"  v-if="!isShowMore">查看更多</span>
              </li>
              <li v-if="fileUrl+car.photolist.acpRightBack">
                <img :src="fileUrl+car.photolist.acpRightBack">
                <span @click="isShowMore=true"  v-if="!isShowMore">查看更多</span>
              </li>
              <li class="clear"></li>
            </ul>

            <ul class="box-3-1" v-if="isShowMore">
              <li v-for="(otherPhoto, index) in otherPhotos" :key="index" >
                <img v-if="otherPhoto" :src="fileUrl+otherPhoto">
              </li>
              <li class="clear"></li>
            </ul>

          </div>

          <div class="box-3">
            <h3>参数配置</h3>

            <div class="table-item">
              <div class="box-3-1"><strong>车辆基本参数</strong></div>
              <div class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
                   style="width: 100%;">
                <div class="el-table__body-wrapper">

                  <div class="param-item" v-for="(baseparam, index) in car.form.baseparams" :key="index">
                    <div class="param-item-name">{{index}}</div>
                    <div class="param-item-val"><table><tr><td align="center" valign="middle">{{baseparam}}</td></tr></table></div>
                  </div>

                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <!--<table cellspacing="0" cellpadding="0" border="0" class="el-table__body" style="width: 100%">
                    <tbody>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>-->
                </div>
              </div>
            </div>

            <div class="table-item">
              <div class="box-3-1"><strong>车辆手续信息</strong></div>
              <div class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
                   style="width: 100%;">
                <div class="el-table__body-wrapper">
                  <div class="param-item" v-for="(baseparam, index) in car.form.proceduresform" :key="index">
                    <div class="param-item-name">{{index}}</div>
                    <div class="param-item-val"><table><tr><td align="center" valign="middle">{{baseparam}}</td></tr></table></div>
                  </div>
                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <!--<table cellspacing="0" cellpadding="0" border="0" class="el-table__body" style="width: 100%">
                    <tbody>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>-->
                </div>
              </div>
            </div>

            <div class="table-item">
              <div class="box-3-1"><strong>车辆配置信息</strong></div>
              <div class="el-table el-table--fit el-table--border el-table--enable-row-hover el-table--enable-row-transition"
                   style="width: 100%;">
                <div class="el-table__body-wrapper">
                  <div class="param-item" v-for="(baseparam, index) in car.form.configform" :key="index">
                    <div class="param-item-name">{{index}}</div>
                    <div class="param-item-val"><table><tr><td align="center" valign="middle">{{baseparam}}</td></tr></table></div>
                  </div>
                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <div class="param-item">
                    <div class="param-item-name"></div>
                    <div class="param-item-val"></div>
                  </div>
                  <!--<table cellspacing="0" cellpadding="0" border="0" class="el-table__body" style="width: 100%">
                    <tbody>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    <tr class="el-table__row">
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          品牌
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          车系
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          排量
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                      <td class="el-table__cell car-arg">
                        <div class="cell">
                          出厂年月
                        </div>
                      </td>
                      <td class="el-table__cell">
                        <div class="cell">
                          (仅供参考)
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>-->
                </div>
              </div>
            </div>




          </div>



        </div>


      </div>

    </div>


    <el-dialog
        title="APP扫码"
        :visible.sync="isShowQrCode"
        width="30%"
    >
      <div class="content-body" style="padding-top: 50px; padding-bottom: 20px;">
        <img class="download-img" src="../../assets/images/download.png">
        <div class="download-item">
          <img src="../../assets/images/download-qr-code-1.jpg">
          <div class="download-id-1">优车优盟竞拍端</div>
          <div class="download-id-2">当前版本  1.0.047</div>
          <a class="download-id-3" href="">安卓客户端</a>
          <a class="download-id-4" href="">iOS客户端</a>
          <div class="clear"></div>
        </div>


      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getCarDetail, getCarOtherPhoto} from "@/api/car"
import carrousel from "@/components/carrousel";

export default {
  components: { carrousel},
  data() {
    return {
      loading: true,
      isShowMore:false,
      otherPhotos:null,
      car:null,
      fileUrl:'http://ucarunion.com:9801/',
      isShowQrCode:false,
    };
  },
  mounted() {
    this.getCarDetail()
    this.getCarOtherPhoto()
  },
  methods: {
    getCarDetail(){
      getCarDetail({paramsAuCode:this.$route.params.id}).then(response => {
        this.car=response.data
      })
    },
    getCarOtherPhoto(){
      getCarOtherPhoto({paramsAuCode:this.$route.params.id}).then(response => {
        this.otherPhotos=response.data[0]
      })
    },
    onClickQrcode(){
      this.isShowQrCode = true
    }
  }
};
</script>

<style lang="scss" scoped>
.download-img{
  position: absolute;
  left: 0;
  top: -95px;
}
.download-item{
  background: #f4f4f4;
  margin-left: 390px;
  margin-bottom: 55px;
  padding: 45px 0 45px 100px;
  img{
    float: left;
    margin-right: 35px;
  }
  .download-id-1{
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: bold;
  }
  .download-id-1{
    font-size: 24px;
  }
  a{
    display: inline-block;
    border-radius: 5px;
    background: #515151;
    color: #fff;
    padding: 15px 40px;
    font-size: 20px;
    margin-right: 40px;
    margin-top: 65px;
  }
  a:hover{
    background: #f4a307;
  }
}
</style>
