<template>
  <div class="carrousel-warp">
    <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
      <swiper-slide v-for="(phone, index) in photos" :key="index" :style="'background: url('+fileUrl+phone+');'"></swiper-slide>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
    </swiper>

    <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
      <swiper-slide v-for="(phone, index) in photos" :key="index" :style="'background: url('+fileUrl+phone+');'"></swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
import env from "@/env"

const fileUrl = env.fileUrl

export default {
  name: 'carrousel',
  props: {
    photos: {
      type: Array,
      default:()=>[null]
    },
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      fileUrl:fileUrl,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      }
    }
  },
  computed: {
    //swiper() {
    //  return this.$refs.mySwiper.$swiper
    //}
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  }
}
</script>

<style lang="scss" scoped>
.carrousel-warp{
  width: 100%;
  height: 590px;
  overflow: hidden;
  .swiper-container{
    height: 100% !important;
  }
  .gallery-top {
    width: 100% !important;
    height: 80% !important;
    background-position: center;
    background-size: cover;
  }
  .gallery-thumbs {
    width: 100% !important;
    height: 20% !important;
    background-position: center;
    background-size: cover;
    .swiper-slide { //等比例缩小
      opacity: 0.4;
      height: 100%;
      width: 151px;
      border: 1px solid #eee;
      background-position: center!important;
      background-size: cover!important;
      cursor: pointer;
    }
    .swiper-slide-active {
      opacity: 1;
    }
  }
}

</style>
